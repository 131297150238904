import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch, } from 'vue-property-decorator';
import { ModifyPasswordService } from '@/sevices';
import { RsaEncrypt } from '@/common/rsg';
import * as Utils from '@/common/utils';
let ModifyPasswordValid = class ModifyPasswordValid extends Vue {
    constructor() {
        super(...arguments);
        /**
         * 表单
         */
        this.form = {
            oldPassword: '',
        };
        /**
         * 表单校验规则
         */
        this.rules = {
            oldPassword: [
                { required: true, message: '请输入原密码', trigger: 'blur' },
            ],
        };
    }
    onFormChange() {
        Utils.trim(this.form);
    }
    /**
     * 确认回调
     */
    submit() {
        this.$refs.form.validate(valid => {
            if (valid) {
                this.requestService();
            }
        });
    }
    async requestService() {
        const oldPassword = RsaEncrypt(this.form.oldPassword, this.rsaKey.key);
        const params = {
            oldPassword,
            index: this.rsaKey.index,
        };
        this.$emit('validingEvent', true);
        ModifyPasswordService.checkOldPassword(params, this.baseUserId).then((res) => {
            const { success, data } = res;
            if (success && data) {
                this.$emit('validHandler', oldPassword);
            }
            else {
                this.$message.warning('密码不正确！');
            }
        }).finally(() => {
            this.$emit('validingEvent', false);
        });
    }
};
__decorate([
    Prop(String)
], ModifyPasswordValid.prototype, "baseUserId", void 0);
__decorate([
    Prop({ default: () => ({}) })
], ModifyPasswordValid.prototype, "rsaKey", void 0);
__decorate([
    Watch('form', { deep: true })
], ModifyPasswordValid.prototype, "onFormChange", null);
ModifyPasswordValid = __decorate([
    Component({
        name: 'ModifyPasswordValid',
    })
], ModifyPasswordValid);
export default ModifyPasswordValid;
